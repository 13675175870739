const About = () => {
    return (
        <section className="bg-slate-800">
            <section className="p-5 pt-10 text-white">
                <h1 className="text-4xl">
                    About us
                </h1>
                <p className="pt-5 leading-loose text-xl">
                PMP Entertainment Pvt Ltd., formerly known as Purple Motion Pictures, is a 15-year-old film production company. We have successfully produced over 50 films and more than 1200 short films. In addition, PMP proudly owns an OTT platform called Purplex, providing a diverse range of content to our viewers. Furthermore, we have a dedicated ground channel, Purple Shorts, which can be accessed on CITI cable (Channel no. 301). For further inquiries, please feel free to contact us at info@pmpentertainment.in
                </p>
            </section>
        </section>
    )
}

export default About
