const Youtube = () => {
    return (
        <>
            <section className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5">
                {
                    channels.map(p => {
                        return (
                                <a href={p.href}>
                                    <section className="grid hover:bg-gray-500 transition-all justify-items-center h-72 xl:h-72 p-3 md:p-5 m-3 md:m-5 bg-gray-700 rounded-2xl border hover:bg-gray-800">
                                        <img src={process.env.PUBLIC_URL+"images/"+p.link} alt={p.name} className="mt-5 rounded-2xl drop-shadow-2xl"/>
                                        <h1 className="pt-3 text-center">
                                            {p.name}
                                        </h1>
                                    </section>
                                </a> 
                        ) 
                    })
                }
            </section>
        </>
    )
}

const channels = [
    {
        id:1,
        name: "Bioscope",
        link: "bioscope.jpg",
        href: "https://www.youtube.com/channel/UCC73UnGN4p62tr1D0q0PwJg"
    },
    {
        id:2,
        name: "Purple Cinema",
        link: "purple cinema.jpg",
        href: "https://www.youtube.com/channel/UCL_QAxfJo6QCmRjz8XvxAhw"
    },
    {
        id:3,
        name: "Purple Cineplex",
        link: "purple cineplex.jpg",
        href: "https://youtube.com/c/PURPLECINEPLEX"
    },
    {
        id:4,
        name: "Purple Divine",
        link: "purple divine.jpg",
        href: "https://www.youtube.com/channel/UCrYns3Zpj_mRVdM47ykCcjw"
    },
    {
        id:5,
        name: "Purple Movies Originals",
        link: "purple movies originals.jpg",
        href: "https://youtube.com/c/PurpleMoviesOriginalss"
    },
    {
        id:6,
        name: "Purple Opera",
        link: "purple opera.jpg",
        href: "https://youtube.com/channel/UCRimq4yfTN6DqFaM9VLx-vg"
    },
    {
        id:7,
        name: "Purple Radio",
        link: "purple radio.jpg",
        href: "https://youtube.com/c/PURPLERADIOS"
    },
    {
        id:8,
        name: "Purple Short Scenes",
        link: "purple short scenes.jpg",
        href: "https://www.youtube.com/channel/UCOP7-qvbdIFzvu1_SpKVBTg"
    },
    {
        id:9,
        name: "Purple Shorts Bd",
        link: "purple shorts bd.jpg",
        href: "https://youtube.com/c/PURPLESHORTSBD"
    },
    {
        id:10,
        name: "Purple Shorts",
        link: "purple shorts.jpg",
        href: "https://youtube.com/channel/UCBHHZGyt9zR_e-HmKbmSHFA"
    },
    {
        id:11,
        name: "Purple Talkies",
        link: "purple talkies.jpg",
        href: "https://youtube.com/channel/UCTK21EPD_H4iqiD_FuE9r7A"
    },
    {
        id:12,
        name: "Purple Muzik",
        link: "purple muzik.jpg",
        href: "https://youtube.com/c/FILMMUSICCHITRAHAR"
    },
    {
        id:13,
        name: "Purple Unplugged",
        link: "purple unplugged.jpg",
        href: "https://www.youtube.com/channel/UC2kCvJ6xRwRlquKvTOBiY4Q"
    },
    {
        id:14,
        name: "Purple Unscene",
        link: "purple unseen.jpg",
        href: "https://www.youtube.com/channel/UCYolXCWcrd7bTo0Arb6jnuw"
    },
    {
        id:15,
        name: "Purple Flix",
        link: "purplex flix.jpg",
        href: "https://youtube.com/channel/UCaa4qPNlXaJv_1uRIclYgfg"
    },
    {
        id:16,
        name: "Hukka Hua",
        link: "hukkahua.jpg",
        href: "https://www.youtube.com/channel/UC2lqXgxqwbQB5kY99Ns5CFg"
    },
    {
        id:17,
        name: "RockX",
        link: "rockx.jpg",
        href: "https://youtube.com/channel/UCICSnGpDaHTD7yDNOqAm19g"
    },
    {
        id:18,
        name:"Crime Bengal",
        link:"crime bengal.jpg",
        href:"https://youtube.com/channel/UCj8--QHhKIc4CCkya8nifYg"
    }
    
]

export default Youtube
