export default function Navbar() {
    return(
        <>
            <nav className="grid grid-cols-2 grid-rows-1 bg-gray-800">
                <section className="flex h-24">
                    <img src={process.env.PUBLIC_URL+"images/purple motion pictures_logo.png"} className="object-contain object-scale-down flex-left" height={512}/>
                </section>
            </nav>
        </>
    )
}