import Navbar from './components/navbar'
import Youtube from './components/youtube'
import OTT from './components/ott'
import About from './components/about'
import Footer from './components/footer' 
import TV from './components/tv'
import Video from './components/video'
import Film from './components/film'
import Series from './components/series'

const App = () => {
  return (
    <div className='bg-gray-900'>
    <Navbar />
    <div className='pb-10 min-h-screen bg-gray-900 h-content'>
      <section className='bg-gray-700 hover:bg-gray-500 transition-all md:w-1/5 m-5 p-8 w-fit text-2xl text-white rounded-xl flex object-center text-center'>
        <a href="https://play.google.com/store/apps/details?id=com.PurpleX.movie" className=''>
          <img src={process.env.PUBLIC_URL+"images/download.png"} className='float-left'  height={10}/>
          <img src={process.env.PUBLIC_URL+"download.jpeg"} className='float-right'  height={10}/>
          
        </a>
      </section>
      <section className='p-5 text-2xl text-white'>
        <h1>
          Watch Now:
        </h1>
        <Video />
        <section>
          <h1>
            Our Feature Film Production 
          </h1>
          <Film />
        </section>
        <section className="py-3">
          <h1>
            Our Web Series
          </h1>
          <Series />
        </section>
        <section className="py-3">
          <h1>
            Our YouTube Channels 
          </h1>
          <Youtube />
        </section>
        <section className="py-3">
          <h1>
            Our OTT
          </h1>
          <OTT />
        </section>
        <section className="py-3">
          <h1>
            Our TV Channels
          </h1>
          <TV />
        </section>
      </section>
    </div>
    <About />
    <Footer />
    </div>
  );
}

export default App;
