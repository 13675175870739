const OTT = () => {
    return (
        <>
            <section className="grid grid-cols-2 md:grid-cols-4">
                {
                    channels.map(p => {
                        return (
                            <a href={p.href}>
                                <section className="hover:bg-gray-500 transition-all grid grid-cols-1  justify-items-center h-72 xl:h-auto p-3 md:p-5 m-3 md:m-5 bg-gray-700 rounded-2xl border drop-shadow-2xl hover:bg-gray-800">
                                    <img src={process.env.PUBLIC_URL+"images/"+p.link} alt={p.name} className="mt-5 rounded-2xl drop-shadow-2xl" />
                                        <h1 className="pt-3 text-center">
                                        {p.name}
                                    </h1> 
                                </section>
                            </a>
                        ) 
                    })
                }
            </section>
        </>
    )
}

const channels = [
    {
        id:2,
        name: "PurpleX",
        link: "purplex logo.jpg",
        href: "https://drive.google.com/file/d/1iG1-7n_0q-1YUQ12kNbA4rPnn0qk4L4_/view?usp=sharing"
    }
]

export default OTT
