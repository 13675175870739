const Footer = () => {
    return (
        <>
            <section className="p-5 pt-10 mt-10 text-xl text-left text-white md:grid-cols-2 md:grid bg-slate-800 md:gap-5">
                <section className="">
                    <a href="mailto:info@pmpentertainment.in" className="text-4xl">
                        Contact Us <br />
                    </a>
                        
                    <a className="" href="https://maps.app.goo.gl/c2EZPVVtBs3Yykw89">
                    <br />
                    Everest House <br />
                    19th floor <br />
                    Room No 19A <br />
                    46C Jawahar Lal Nehru Road Kolkata 700071 <br />
                    West Bengal, India <br />
                    </a>
                    <br />
                    <a className="" href="mailto:info@pmpentertainment.in" >
                        Mail: info@pmpentertainment.in
                    </a>
                </section>
            </section>
        </>
    )
}

export default Footer
