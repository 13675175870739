const Series = () => {
    return (
        <>
            <section className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5">
                {
                    channels.map(p => {
                        return (
                                <section className="hover:bg-gray-500 transition-all justify-items-center p-3 md:p-5 m-3 md:m-5 bg-gray-700 rounded-2xl border drop-shadow-2xl hover:bg-gray-800">
                                    <img src={process.env.PUBLIC_URL+"images/"+p.link} alt={p.name} className="mt-5 rounded-2xl" />
                                </section>
                        ) 
                    })
                }
            </section>
        </>
    )
}

const channels = [
    {
        id:1,
        name: "Asati",
        link: "asati.jpg",
    },
    {
        id:2,
        name: "Beauty Kakima 2",
        link: "beautykakima2.jpg",
    },
    {
        id:3,
        name: "Murder",
        link: "murder.jpg",
    },
    {
        id:4,
        name: "Room No. 666",
        link: "scary_room.jpg",
    },
    
]

export default Series
