const Film = () => {
    return (
        <>
            <section className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5">
                {
                    channels.map(p => {
                        return (
                                <section className="hover:bg-gray-500 transition-all hover:drop-shadow-2xl justify-items-center p-3 md:p-5 m-3 md:m-5 bg-gray-700 rounded-2xl border drop-shadow-sm hover:bg-gray-800">
                                    <img src={process.env.PUBLIC_URL+"images/"+p.link} alt={p.name} className="mt-5 rounded-2xl" />
                                </section>
                        ) 
                    })
                }
                <a href="https://youtu.be/mcH1bLGWsd4">
                <section className="hover:bg-gray-500 transition-all hover:drop-shadow-2xl justify-items-center p-3 md:p-5 m-3 md:m-5 bg-gray-700 rounded-2xl border drop-shadow-sm hover:bg-gray-800">
                    <img src={process.env.PUBLIC_URL+"images/"+"stranger.jpeg"} alt={"Stranger"} className="mt-5 rounded-2xl" />
                </section>
                </a>
                
            </section>
        </>
    )
}

const channels = [
    {
        id:1,
        name: "Love Hate Dhoka",
        link: "love hate dhoka.jpg",
    },
    {
        id:2,
        name: "Love Hate Dhoka 2",
        link: "love hate dhoka 2.jpg",
    },
    {
        id:3,
        name: "Mask",
        link: "mask.jpg",
    },
    {
        id:4,
        name: "Piklu",
        link: "piklu.jpg",
    },
    {
        id:5,
        name: "The Last",
        link: "the last call.jpg",
    },
    {
        id:6,
        name: "Janbaj",
        link: "janbaj.jpg",
    },
    {
        id:7,
        name: "noclue",
        link: "noclue.jpg",
    },
    {
        id:8,
        name: "Picnic",
        link: "picnic.jpg",
    },
    {
        id:9,
        name: "Standee",
        link: "Standee.jpg",
    },
    {
        id:10,
        name: "wts",
        link: "wts.jpg",
    },
    {
        id:11,
        name: "Tota",
        link: "Tota.jpg",
    },
    {
        id:12,
        name: "Yudh",
        link: "yudh.jpg",
    },
    {
        id:13,
        name: "Dark Lane",
        link: "darklane.jpg",
    },
    {
        id:14,
        name: "Underground",
        link: "underground.jpg",
    },
    {
        id:15,
        name: "The Deal",
        link: "thedeal.jpg",
    },
    
    
    
]

export default Film