const TV = () => {
    return (
        <>
            <section className="grid grid-cols-2 md:grid-cols-4">
                {
                    channels.map(p => {
                        return (
                            <a href={p.href}>
                                <section className="grid grid-cols-1 hover:bg-gray-500 transition-all justify-items-center h-72 xl:h-auto p-3 md:p-5 m-3 md:m-5 bg-gray-700 rounded-2xl border drop-shadow-2xl hover:bg-gray-800">
                                    <img src={process.env.PUBLIC_URL+"images/"+p.link} alt={p.name} className="mt-5 rounded-2xl drop-shadow-2xl" />
                                        <h1 className="pt-3 text-center">
                                        {p.name}
                                    </h1> 
                                </section>
                            </a>
                        ) 
                    })
                }
            </section>
        </>
    )
}

const channels = [
    {
        id:1,
        name: "Purple Shorts TV",
        link: "purpleshorts.jpg",
        href: "http://streamcp.logosys.cloud:1935/purple/purple/playlist.m3u8"
    },
]

export default TV