import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from 'swiper';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation"
import "swiper/css/autoplay"

// import required modules
import { Pagination, Navigation, Scrollbar } from "swiper";

export default function Video() {
  SwiperCore.use([Autoplay]);
  return (
    <>
      <Swiper className="mySwiper" autoplay={{delay: 5000, disableOnInteraction: false,}}
                      modules={[Navigation, Pagination, Scrollbar]}
                      spaceBetween={50}
                      slidesPerView={1}
                      navigation={true}
                      pagination={{ clickable: true }}
                      scrollbar={{ draggable: true }}
                      loop={true}>
        {
            channels.map(p => {
                return (
                    <SwiperSlide 
                      className="mySwiper">
                        <section className="flex m-10 md:m-20 aspect-w-16 aspect-h-9">
                          <iframe title="title1" frameborder="0" src={"https://www.youtube.com/embed/"+p.link} className=''/>
                          
                        </section>
                    </SwiperSlide>
                    
                )
            })
        }
      </Swiper>
    </>
  );
}


const channels = [
    {
        id:1,
        link:"-fTaVtD48rI",
    },
    {
        id:2,
        link:"GD4y4Y9x8z0",
    },
    {
      id:3,
      link:"KfQ8dNP2Aqw"
    },
    {
      id:4,
      link:"_KccSNN-EPk"
    },
    {
      id:5,
      link:"mcH1bLGWsd4"
    }
]
